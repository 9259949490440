<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="[
      itemsLoading ? 'data-table--loading' : '',
      canBeSelected ? 'data-table--selectable' : '',
    ]"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :item-class="itemClass"
    >
      <template v-if="itemsLoading" v-slot:body>
        <template v-if="$vuetify.breakpoint.lgAndDown">
          <logs-table-item-skeleton-mobile v-for="item in 5" :key="item" />
        </template>
        <template v-else>
          <logs-table-sort-skeleton />
          <logs-table-skeleton-item />
        </template>
      </template>

      <template v-slot:no-data>
        <slot name="no-data" />
      </template>
      <template v-slot:no-results>
        <data-iterator-no-results-container
          v-if="searchTerm"
          @clearAllFilters="$emit('clearFilters')"
          :searchTerm="searchTerm"
        />
      </template>

      <template
        v-if="!$vuetify.breakpoint.mobile && items.length"
        v-slot:header="{ isMobile, props, on }"
      >
        <table-custom-sort
          v-if="!itemsLoading"
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          v-on="$listeners"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex actions-row">
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                :loading="item.infoButtonLoading"
                @click="$emit('action-button-info', item)"
              >
                <v-icon>$help</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.ftp.show') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-edit', item)"
              >
                <v-icon>$edit2</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.ftp.edit') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                small
                @click="$emit('action-button-delete', item)"
              >
                <v-icon>$thrash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('button.ftp.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.user="{ item }">
        <b>
          {{ item.user }}
        </b>
      </template>

      <template v-slot:item.updated_at="{ item }">
        <div class="d-flex">
          <slot name="updated_at" v-bind="item"> </slot>
        </div>
      </template>

      <template v-slot:item.usage_quota="{ item }">
        <div v-if="usageSupported" class="d-flex align-center">
          <progress-chart
            :style="`max-width: 150px; min-width: 150px`"
            :value="parseInt(item.diskused)"
            :maxValue="
              item.diskquota !== 'unlimited'
                ? parseInt(item.diskquota)
                : parseInt(item.diskused)
            "
          />
          <span class="usage-quota">
            <b class="font-weight-600">{{ $t('form.value.sizeMB', {value: item.diskused}) }}</b> /
            <span>
              <span v-if="item.diskquota !== 'unlimited'">{{ $t('form.value.sizeMB', {value: item.diskquota}) }}</span>
              <span v-else>{{ item.diskquota }}</span>
            </span>
          </span>
        </div>
        <div v-else class="ps-7">
          <v-menu
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            origin="top center"
            offset-overflow
            :close-on-content-click="false"
            open-on-hover
            nudge-bottom="24px"
            nudge-left="175px"
            min-width="350px"
            max-width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1 d-flex align-center"
                x-small
                icon
                text
                v-on="on"
                v-bind="attrs"
              >
                <v-icon color="black" size="20">$alertcircle</v-icon>
              </v-btn>
            </template>

            <div class="px-4 py-4">
              {{ $t('tooltip.hosting.ftp.usageNotSupported') }}
            </div>
          </v-menu>
        </div>
      </template>

      <template
        v-if="$vuetify.breakpoint.mobile"
        v-slot:item="{ item, headers }"
      >
        <tr>
          <td class="w-100">
            <div class="mobile-table-item">
              <div class="mobile-table-item__values">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.user') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.user }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.directory') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.directory }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[2])"
                  >
                    {{ $t('table.header.usageQuota') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="p-3" v-if="!item.usage_quota">"---"</span>
                    <div class="d-flex align-center" v-else>
                      <progress-chart
                        :style="`max-width: 150px; min-width: 150px`"
                        :value="parseInt(item.diskused)"
                        :maxValue="
                          item.diskquota !== 'unlimited'
                            ? parseInt(item.diskquota)
                            : parseInt(item.diskused)
                        "
                      />
                      <span class="ml-2"> {{ item.usage_quota }} </span>
                    </div>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.actions') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          :loading="item.infoButtonLoading"
                          @click="$emit('action-button-info', item)"
                        >
                          <v-icon>$help</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.ftp.show') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="$emit('action-button-edit', item)"
                        >
                          <v-icon>$edit2</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.ftp.edit') }}</span>
                    </v-tooltip>
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      nudge-bottom="4px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-on="on"
                          v-bind="attrs"
                          icon
                          small
                          @click="$emit('action-button-delete', item)"
                        >
                          <v-icon>$thrash</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('button.ftp.delete') }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>

      <template v-slot:footer="{ props }" v-if="items.length">
        <div>
          <data-iterator-footer
            :page="props.pagination.page"
            :numberOfPages="props.pagination.pageCount"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="props.pagination.itemsPerPage"
            v-on="$listeners"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DataIteratorMixin from "../../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../../mixins/CustomTableMixin";

import DataIteratorFooter from "../DataIteratorFooter.vue";
import TableCustomSort from "../TableCustomSort.vue";
import LogsTableSkeletonItem from "./LogsTableSkeletonItem.vue";
import LogsTableSortSkeleton from "./LogsTableSortSkeleton.vue";
import LogsTableItemSkeletonMobile from "./LogsTableItemSkeletonMobile.vue";
import DataIteratorNoResultsContainer from "../DataIteratorNoResultsContainer.vue";
import ProgressChart from "../../charts/ProgressChart.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    LogsTableSkeletonItem,
    LogsTableSortSkeleton,
    LogsTableItemSkeletonMobile,
    ProgressChart,
    DataIteratorNoResultsContainer,
  },
  data: function () {
    return {
      canBeSelected: false,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
    itemClass: Function,
    usageSupported: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 8px;
}

.params {
  max-width: 450px;
}
.data-table {
  .v-progress-linear {
    display: block;
  }
  .actions-row {
    justify-content: flex-start;
    gap: 12px;
    &:last-child {
      justify-content: flex-end;
    }
    .custom-sort::v-deep {
      .sort-item:last-child {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        height: 56px;
      }
    }
  }
  .usage-quota {
    white-space: nowrap;
    margin-left: 8px;
    > span {
      text-transform: capitalize;
    }
  }
}
.v-application--is-rtl{
    .data-table{
        .usage-quota{
            margin-left: 0px;
            margin-right: 8px;
        }
    }
}
</style>
